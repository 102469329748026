<template>
	<div
		class="mt-30 text-left flex-row"
	>
		<drops_side
			@click="setCardType"
		></drops_side>

		<div
			class="flex-1"
		>
			<div class="under-line-bbb pb-16 flex-row">
				<h3 class="flex-1 size-px-20">{{ item_card_type.name }}</h3>
			</div>

			<div>
				<template
					v-if="card_type_code == 'NF00100005'"
				>
					<ul
						v-if="card_list.length > 0 "
						class="card_list mt-10"
					>
						<li
							v-for="(item, index) in card_list"
							:key="'item_' + index"
							class="main_card main_card_type2"
						>
							<figure>
								<div class="frame frame_type2 thumbnail-wrapper">
									<div class="thumbnail">
										<img :src="item.sl_img_url" alt="SPY">
									</div>
									<figcaption class="thumbnail_txt">
										<span class="name">{{ item.nft_card_grade_name }}</span>
										<em class="type">{{ item.nft_card_div_name }}</em>
										<strong class="price color-white font-weight-bold">{{ item.price | makeComma(8) }} {{ text_unit }}</strong>
									</figcaption>
								</div>
							</figure>
							<button
								v-if="item.max > 0"
								class="btn_m btn_fill_blue w-100 mt-10"
								:disabled="item.sl_state_code != 'NF01500004'"
								@click="toBuy(item)"
							>BUY</button>
							<button
								v-else
								class="btn_m btn_fill_blue w-100 mt-10"
								disabled
							>SOLD OUT</button>
						</li>
					</ul>

					<div
						class="none pa-50"
					>판매중인 UNION 카드가 없습니다.</div>
				</template>

				<div
					v-else
					class="gift_box"
				>
					<a
						class="gift_card mafia_card"
						:class="{card_sold_out: ! is_purchase.nft}"
						@click="on03911('nft')"
					>
						<div class="tit pa-30">
							<div class="text-center">
								<img
									:src="require('@/assets/image/gift_l.png')" alt="MAFIA CARD BUY"
								/>
							</div>
							<div
								class="text-center"
							>
								<span class="size-px-16 font-weight-600" style="color:#dc505c;">MAFIA CARD BUY</span>

								<div
									v-if="is_purchase.nft"
									class=""
								>
									<p class="color-white font-weight-bold size-px-20" > {{ text_unit }}{{ item_nft_info.sl_leglter_price | makeComma(8) }}</p>
									<p class="color-white mt-10 font-weight-400 size-px-14" >랜덤 NFT 카드를 구입하세요.</p>
								</div>
								<div
									v-else
									class="color-white mt-30"
								>SOLD OUT !!</div>
							</div>

						</div>
					</a>
				</div>
			</div>
		</div>

		<mafia03911
			v-if="is_03911"
			:currencies="blockchain_list"
			:legal="item_nft"

			@click="setItem"
			@credit="setCredit"
			@cancel="is_03911 = false"
		></mafia03911>
	</div>
</template>

<script>
import mafia03911 from "@/view/Drops/mafia039-1-1";
import drops_side from "@/view/Drops/drops_side";
export default {
	name: 'mafia0391'
	, props: ['user']
	, components: {drops_side, mafia03911 }
	, data: function () {
		return {
			program: {
				name: 'DROPS'
				, title: 'DROPS'
				, type: 'drops'
			}
			, items: []
			, card_type_code: 'NF00100007'
			, blockchain_div_code: 'BC00100002'
			, card_type: ''
			, is_03911: false
			, item_nft: {
				sl_coin_token_list: [
					{}, {}
				]
			}
			, sl_coin_token_list: []
			, item_select_coin: {
				sl_coin_token_name: ''
			}
			, items_card_type: [
				{ code: 'NF00100005', name: 'UNION'}
				, { code: 'NF00100007', name: 'MAFIA NFT'}
			]
			, item_card_type: {
				code: 'NF00100007', name: 'MAFIA NFT'
			}
		}
	}
	, computed: {
		text_unit: function(){
			let t = ''
			switch((this.item_select_coin.sl_coin_token_name).toLowerCase()){
				case 'klaytn':
					t = 'KLAY'
					break
				case 'eth':
					t = 'ETH'
					break
				case 'reap':
					t = 'REAP'
					break
				case 'mafi':
					t = 'MAFI'
					break
				case 'Reap':
					t = 'REAP'
					break
			}
			switch (this.item_nft.sl_leglter_div_code){
				case 'BC00800001':
					t = '₩'
					break;
				case 'BC00800002':
					t = '$'
					break;
				default:
					t = '$'
					break;
			}
			return t
		}
		, item_nft_info: function(){
			let t = this.item_nft

			t.price = t.sl_coin_token_list[1].sl_coin_token_quantity
			t.sl_coin_token_code = t.sl_coin_token_list[1].sl_coin_token_code
			t.blockchain_div_code = t.sl_coin_token_list[1].blockchain_div_code
			t.sl_coin_token_name = t.sl_coin_token_list[1].sl_coin_token_name

			t.sl_coin_token_list.filter( (coin) => {
				if (coin.sl_coin_token_code == this.item_select_coin.sl_coin_token_code) {
					t.price = coin.sl_coin_token_quantity
					t.sl_coin_token_code = coin.sl_coin_token_code
					t.blockchain_div_code = coin.blockchain_div_code
					t.sl_coin_token_name = coin.sl_coin_token_name
				}
			})

			return t
		}
		, card_list: function() {
			// let t = []
			return this.items.filter((item) => {
				return item.sl_coin_token_list.filter((coin) => {
					if (coin.blockchain_div_code == this.item_select_coin.blockchain_div_code) {
						item.price = coin.sl_coin_token_quantity
						item.sl_coin_token_code = coin.sl_coin_token_code
						item.blockchain_div_code = coin.blockchain_div_code
						item.max = item.sl_quantity - item.sl_compl_quantity
					}
				})
			})
			//return t
		}
		, is_purchase: function(){
			let t = {
				nft: false
				, utility: false
			}

			if(this.item_nft.sl_state_code == 'NF01500004'){
				t.nft = true
			}

			return t
		}
		, blockchain_list: function(){
			let t = [
				{ sl_coin_token_name: 'REAP', blockchain_div_code: 'BC00100002' }
				, { sl_coin_token_name: 'KLAY', blockchain_div_code: 'BC00100001' }
			]
			if(this.card_type_code == 'NF00100005'){
				t = this.items[0].sl_coin_token_list
			}else{
				t = this.item_nft.sl_coin_token_list
			}

			return t.filter(function(item){
				switch (item.sl_coin_token_code){
					case 'BC00200001':
						item.label = 'ETH'
						break;
					case 'BC00200002':
						item.label = 'KLAY'
						break;
					case 'BC00200003':
						item.label = 'MAFI'
						break;
					case 'BC00200004':
						item.label = 'MAFI'
						break;
					case 'BC00200005':
						item.label = 'REAP'
						break;
				}
				return item
			})
		}
		, max: function(){
			let t = {
				union: 0
				, nft: 0
			}

			t.nft = this.item_nft.sl_quantity - this.item_nft.sl_compl_quantity

			return t
		}
	}
	, methods: {
		getData: async function () {
			try {
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_sales_card_list
					, data: {
						member_number: this.user.member_number
					}
				})

				if (result.success) {
					this.items = result.data.nft_card_sl_list
					this.item_select_coin = this.items[0].sl_coin_token_list[0]
				} else {
					throw result.message
				}
			} catch (e) {
				this.$bus.$emit('notify', {type: 'error', message: e})
			} finally {
				this.$bus.$emit('on', false)
			}
		}
		, toNft: function () {
			if (this.is_purchase.nft) {
				this.$emit('to', {name: 'mafia040', params: {card_type: 'nft'}})
			}
		}
		, toUtility: function () {
			if (this.is_purchase.utility) {
				this.$emit('to', {name: 'mafia040', params: {card_type: 'utility'}})
			}
		}
		, toBuy: function(item){
			this.is_wait = true

			this.$log.console('toBuy', item)
			this.$bus.$emit('to', { name: 'mafia0392', params: { nft_card_div_code: item.nft_card_div_code, nft_sl_number: item.nft_sl_number, blockchain_div_code: item.blockchain_div_code, sl_coin_token_code: item.sl_coin_token_code}})
		}
		, toBuyNft: function(){
			//this.$bus.$emit('to', { name: 'mafia0392', params: { nft_card_div_code: this.item_nft.nft_card_div_code, nft_sl_number: this.item_nft.nft_sl_number, blockchain_div_code: this.item_nft.blockchain_div_code, sl_coin_token_code: this.item_nft.sl_coin_token_code}})

			this.$bus.$emit('to', { name: 'mafia0392', params: { nft_card_div_code: this.item_nft.nft_card_div_code, nft_sl_number: this.item_nft.nft_sl_number, blockchain_div_code: this.item_select_coin.blockchain_div_code, sl_coin_token_code: this.item_select_coin.sl_coin_token_code}})
		}
		, on03911: function(type){
			this.card_type = type
			this.is_03911 = true
		}
		, setItem: function(item){
			this.$log.console('setItem', item)
			this.item_select_coin = item
			this.is_03911 = false
			this.blockchain_div_code = item.blockchain_div_code
			if(this.card_type == 'nft'){
				this.toBuyNft()
			}
		}
		, getNftConfig: async function(){

			try {
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_drops_config
					, data: {
						member_number: this.user.member_number
					}
					, type: true
				})

				if(result.success){
					this.item_nft = result.data
					this.item_select_coin = this.item_nft.sl_coin_token_list[1]
					let t = this.item_nft.sl_quantity - this.item_nft.sl_compl_quantity
					if(this.max > t){
						this.max = t
					}
					this.$log.console('this.item_nft', this.item_nft)
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, setCardType: function(card_type){
			this.item_card_type = card_type
			let card_type_code = card_type.code
			if(this.card_type_code == card_type_code){
				return false
			}else{
				this.card_type_code = card_type_code

				let t = false
				this.blockchain_list.forEach(coin => {
					if(this.item_select_coin.blockchain_div_code == coin.blockchain_div_code){
						if(coin.blockchain_div_code == 'BC00100002'){
							if(this.item_select_coin == coin.item_select_coin){
								t = true
							}
						}else{
							t = true
						}
						return
					}
				})
				if(!t){
					this.item_select_coin = this.blockchain_list[0]
				}
			}
		}
		, setCredit: function(){

			this.$bus.$emit('to', { name: 'mafia03921', params: { nft_card_div_code: this.item_nft.nft_card_div_code, nft_sl_number: this.item_nft.nft_sl_number, blockchain_div_code: this.item_select_coin.blockchain_div_code, sl_coin_token_code: this.item_select_coin.sl_coin_token_code}})
		}
	}

	, created() {
		this.$emit('onLoad', this.program)
		// this.getData()
		this.getNftConfig()
	}
}
</script>

<style>
.bg-d { background-color: #DC505C !important; opacity: 0.25; font-weight: 500}
.focus { opacity: 1}
a.focus::after { border-color: #DC505C  transparent transparent !important;}
a.focus::before { border-color: #DC505C  transparent transparent !important;}
.bg-n { background-color: transparent !important;}

.text-payment-div {
	color: white; font-size: 1.4rem; font-weight: 500; font-family: Noto Sans KR
}
.text-payment-coin {
	color: white; font-size: 1.4rem; font-weight: 700; font-family: Open Sans
}
.color-white {
	color: white;
}
.text-nft-price {
	color: white; font-size: 2rem; font-weight: 700; font-family: 'Open Sans'
}
.text-nft-info{
	color: #bbb; font-size: 1.4rem; font-weight: 400; font-family: 'Noto Sans KR'; margin-top: 20px
}

.drops.bg_dark {
	height: 100%;
}
.drops .container {
	height: 100%; display: flex; flex-direction: column; justify-content: center;
}
.gift_box{
	padding: 80px 0;
}
.gift_box a{
	min-width: 400px;
	min-height: 400px;
}
.gift_box a.mafia_card{
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.4) 65%), url("../../assets/image/web/nft_collection/bg_dropbox_lg@2x.jpg");
	background-size: cover;
}
.gift_box a .tit{
	min-height: 400px;
	max-height: 400px;
	justify-content: center;
}
.gift_box a .tit img{
	width: 130px;
}
</style>
